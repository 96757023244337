import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import ModalVideo from "../../components/ModalVideo/ModalVideo";
import GlobalContext from "../../context/GlobalContext";

const ImgRight = styled.img`
  max-width: 100%;
`;

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box className="text-right">
                <Text
                  variant="tag"
                  mb={4}
                  className="text-right"
                  color="heading"
                  mb="40px"
                >
                  GRAPHIC DESIGNER, ILLUSTRATOR, CRAFTER
                </Text>
                <Title variant="hero">Hello World,</Title>
                <Title variant="hero">
                  This is Daniella Herrera and her collection of pixels
                </Title>

                {/* Video modal on lg+, link to video on mobile */}
                <ModalVideo />
                <Button
                  onClick={gContext.toggleVideoModal}
                  className="d-none d-lg-inline-flex"
                >
                  Graphic Design Video Reel
                </Button>
                <a href="https://youtu.be/3cKWWgOnOWw">
                  <Button className="d-inline-flex d-lg-none">
                    Graphic Design Video Reel
                  </Button>
                </a>

                <Box mt="52px">
                  <Link
                    to="works"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  ></Link>
                </Box>
              </Box>
            </Col>
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <img
                    src="/portraits/portrait.png?nf_resize=fit&w=445"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
